import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    maxWidth: "80%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    padding: 0,
    maxHeight: 600,
    overflowY: "auto",
  },
  table: {
    minWidth: 350,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    background: '#3B6894',
    color: 'white',
    padding: '0 30px'
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    padding: '0 30px',
  },
  modalWrapper: {
    padding: '50px',
  }
}));

export default function WarrantyErrorModal({
  errors,
  open,
  setOpen,
  setFile,
  setErrors,
  setInputKey,
}) {
  const classes = useStyles();

  const handleClose = () => {
    setFile(null);
    setErrors([]);
    setInputKey(Date.now());
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
        className="modalWrapper"
      >
        <div className={classes.modal}>
          <div className={classes.header}>
            <h2 id="error-modal-title">Error Details</h2>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <p className={classes.errorMessage}>
            This error occurred in your uploaded sheet. Please correct the
            entries and upload again.
          </p>
          <Table className={classes.table} aria-label="error table">
            <TableHead>
              <TableRow>
                <TableCell>Row</TableCell>
                <TableCell align="right">Field Name</TableCell>
                <TableCell align="right">Error Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errors?.map((error, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {error?.row}
                  </TableCell>
                  <TableCell align="right">{error?.field}</TableCell>
                  <TableCell align="right">{error?.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Modal>
    </div>
  );
}
