import React, { useState, useEffect } from "react";
import { Mutation, Query, Subscription } from "react-apollo";
import { useLazyQuery } from "@apollo/client";
import {
  UPDATE_MAIL_LEAD,
  getAllLeads,
  getCustomerforLetterHead,
  getDealerforLetterRequest,
  getLeadSendCount,
  getNearestDealerToLead,
} from "../../../queries";
import PropTypes from "prop-types";
import {
  Button,
  TableRow,
  TableCell,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Print } from "@material-ui/icons";
import {
  SelectionState,
  RowDetailState,
  SearchState,
  PagingState,
  SortingState,
  IntegratedSorting,
  IntegratedPaging,
  IntegratedFiltering,
  IntegratedSelection,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
// Shared layouts
import { Dashboard as DashboardLayout } from "layouts";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import LeadsTransfer from "../LeadsTransfer";
import LeadsFulfilledBy from "../LeadsFulfilledBy";
import LeadsOptionsRender from "../LeadsOptionsRender";
import LeadsCurrentDealer from "../LeadsCurrentDealer";
import { CSVLink } from "react-csv";
import GridContainer from "@material-ui/core/Grid";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CatalogRequestPdfDocument } from "./CatalogRequestPdfDocument";
import auth from "Auth";

// Component styles
import styles from "theme/skin";
import LeadImport from "./LeadImport";

const RowDetail = ({ row }) => {
  const [data, setData] = useState();
  const [getLetterHead, letterHead] = useLazyQuery(getCustomerforLetterHead);
  const [getDealerforLetter, dealerforLetter] = useLazyQuery(
    getDealerforLetterRequest
  );
  const [getNearestDealers, nearestDealers] = useLazyQuery(
    getNearestDealerToLead
  );

  // triggers when the expnd button is pressed
  useEffect(() => {
    if (row.id) {
      getNearestDealers({
        variables: {
          id: row?.id,
        },
      });
      getDealerforLetter({
        variables: {
          profile_id: row?.profile_id,
        },
      });
      getLetterHead({
        variables: {
          id: row?.id,
        },
      });
    }
  }, [row]);

  useEffect(() => {
    if (dealerforLetter?.data && letterHead?.data && nearestDealers?.data) {
      setData({
        ...row,
        uri: `${window.location.origin}/images/pdf_header.png`,
        dealerforLetter: dealerforLetter?.data,
        letterHead: letterHead?.data,
        nearestDealers: nearestDealers?.data,
      });
    }
  }, [dealerforLetter, letterHead, nearestDealers]);
 
  return (
    <div>
      {data && (
        <PDFDownloadLink
          document={<CatalogRequestPdfDocument data={data} />}
          fileName={
            "catalog_request_" + row.lead_type + "_" + row.email + ".pdf"
          }
          color="secondary"
          className="MuiButtonBase-root MuiButton-root MuiButton-containedSecondary MuiButton-contained"
        >
          {({ blob, url, loading, error }) =>
            (loading ||
              dealerforLetter.loading ||
              letterHead.loading ||
              nearestDealers.loading,
            !data)
              ? "Loading document..."
              : "Print To Pdf"
          }
        </PDFDownloadLink>
      )}
      <table cellSpacing="0" className="detailTable">
        <tbody>
          <tr>
            <td>Current Dealer:</td>
            <td>{row?.dealer_name}</td>
          </tr>
          <tr>
            <td>Lead ID:</td>
            <td>{row?.id}</td>
          </tr>
          <tr>
            <td>Lead Type:</td>
            <td>{row?.lead_type}</td>
          </tr>
          <tr>
            <td>Brand:</td>
            <td>{row?.brand}</td>
          </tr>
          <tr>
            <td>Status:</td>
            <td>{row?.status}</td>
          </tr>
          <tr>
            <td>Address:</td>
            <td>{row?.address}</td>
          </tr>
          <tr>
            <td>Country:</td>
            <td>{row?.country}</td>
          </tr>
          <tr>
            <td>Email:</td>
            <td>{row?.email}</td>
          </tr>
          <tr>
            <td>Phone:</td>
            <td>{row?.phone}</td>
          </tr>
          <tr>
            <td>Custom Options:</td>
            <td>
              <LeadsOptionsRender options={row?.options} />
            </td>
          </tr>
        </tbody>
      </table>
      {auth.getRole().toLowerCase() === "admin" ? (
        <LeadsTransfer lead={row} id={row?.id} />
      ) : (
        ""
      )}
      <LeadsFulfilledBy tag={row?.fulfilledby} id={row?.id} />
    </div>
  );
};

class Leads extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "lead_type", title: "Lead Type" },
        { name: "dealer_name", title: "Dealership" },
        { name: "lead_id", title: "#" },
        { name: "lead_date", title: "Date" },
        { name: "brand", title: "Brand" },
        { name: "name", title: "Name" },
        { name: "fulfilledby", title: "Fulfilled By" },
        { name: "fulfilledon", title: "Fulfilled On" },
      ],
      leadsData: [],
      selectedRows: [],
    };
    this.changeSearchValue = (value) => this.setState({ searchValue: value });
  }

  changeSelection = (selectedRows) => {
    this.setState({ selectedRows });
  };

  objectToXml = require("object-to-xml");

  handleConvertToXML = async (data) => {
    const selectedData = this.state.selectedRows.map((selectedIndex) => {
      return this.state.leadsData[selectedIndex];
    });

    if (selectedData.length === 0) {
      selectedData.push(...this.state.leadsData);
    }

    for (let index = 0; index < selectedData.length; index++) {
      const rowData = selectedData[index];

      // Convert JavaScript object to XML
      const xmlData = this.objectToXml({ root: rowData });

      // Create a Blob containing the XML data
      const blob = new Blob([xmlData], { type: "application/xml" });

      // Create a download link
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = `lead_${index + 1}.xml`;

      // Trigger the download
      a.click();

      // Wait for the previous download to complete before initiating the next one
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Add a delay of 1 second between downloads
    }
  };

  render() {
    const { columns, selection, searchValue } = this.state;
    const { classes } = this.props;
    return (
      <DashboardLayout title="Leads">
        <Query query={getAllLeads}>
          {({ data, loading, error }) => {
            if (loading)
              return (
                <div className="flex center" style={{ padding: "2em 0" }}>
                  <CircularProgress color="secondary" />
                </div>
              );
            if (error) return <p>Error</p>;
            this.setState({ leadsData: data?.leads_with_dealer_info });
            for (let lead of data?.leads_with_dealer_info) {
              let address = lead?.address?.trim();
              address = address?.replace("Not Applicable", "Not-Applicable");
              let addressReversed = address?.split(" ")?.reverse();
              lead.postalCode = addressReversed?.shift();
              if (addressReversed) {
                addressReversed.shift(); // country is already present.
              }
              lead.state = (addressReversed?.shift() || "")?.replace(
                "Not-Applicable",
                "Not Applicable"
              );
              lead.city = addressReversed?.shift();
            }
            return (
              <div className={classes.root + " sectionContainer"}>
                <Typography gutterBottom variant="h4" className="title">
                  <span>Leads</span>
                </Typography>
                <Paper>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "25px",
                    }}
                  >
                    <LeadImport />
                  </div>
                </Paper>
                <br />

                <Paper className="tableStyles">
                  <div
                    className={classes.header}
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span className={classes.headerTitle}>All New Leads</span>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Subscription subscription={getLeadSendCount}>
                        {({ data, loading, error }) => {
                          if (loading)
                            return (
                              <div
                                className="flex center"
                                style={{ padding: "2em 0" }}
                              >
                                <CircularProgress color="secondary" />
                              </div>
                            );

                          return (
                            <Mutation mutation={UPDATE_MAIL_LEAD}>
                              {(updateLead, { loading, called, error }) => {
                                if (error)
                                  return (
                                    <pre>
                                      Bad:{" "}
                                      {error.graphQLErrors.map(
                                        ({ message }, i) => (
                                          <span key={i}>{message}</span>
                                        )
                                      )}
                                    </pre>
                                  );
                                return (
                                  <Button
                                    disabled={
                                      data?.leads_aggregate?.aggregate?.count <
                                      1
                                    }
                                    variant="contained"
                                    color="primary"
                                    style={{ marginRight: "10px" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      updateLead({
                                        variables: {
                                          profile_id:
                                            "auth0|5e4186946922b70f18a7b161",
                                        },
                                      });
                                    }}
                                  >
                                    Leads Sync
                                  </Button>
                                );
                              }}
                            </Mutation>
                          );
                        }}
                      </Subscription>
                      <GridContainer
                        container
                        justify="space-between"
                        className={classes.iconHeaderContainer}
                      >
                        <GridContainer item xs={12} sm={3}>
                          <a href="javascript:window.print()">
                            <Print className={classes.printIcon} />
                          </a>
                        </GridContainer>
                        <GridContainer item xs={12} sm={3}>
                          <CSVLink
                            data={data?.leads_with_dealer_info?.map((item) => ({
                              Id: item?.id || "",
                              "Profile Id": item?.profile_id || "",
                              "Dealer Name": item?.dealer_name || "",
                              "Lead Id": item?.lead_id || "",
                              "Lead Type": item?.lead_type || "",
                              "Created At": item?.created_at || "",
                              Status: item?.status || "",
                              Address: item?.address || "",
                              Country: item?.country || "",
                              Email: item?.email || "",
                              Phone: item?.phone || "",
                              "Heard About":
                                item?.heard_about !== null
                                  ? item?.heard_about
                                  : "",
                              Magazines: item?.magazines || "",
                              "Time Frame": item?.time_frame || "",
                              Ownership: item?.ownership || "",
                              Interests: item?.interests || "",
                              Age: item?.age !== null ? item?.age : "",
                              "Annual Income":
                                item?.annual_income !== null
                                  ? item?.annual_income
                                  : "",
                              Brand: item?.brand || "",
                              "Lead Date": item?.lead_date || "",
                              Name: item?.name || "",
                              Options: item?.options || "",
                              Fulfilledby: item?.fulfilledby || "",
                              Fulfilledon: item?.fulfilledon || "",
                              Location: item?.location
                                ? `{type : ${item?.location?.type},crs:{type:${item?.location.crs?.type},properties:{name:urn:ogc:def:crs:EPSG::${item?.location?.crs?.properties?.name}}},coordinates:[${item?.location.coordinates?.[0]},${item?.location.coordinates?.[1]}]}`
                                : "",
                              __Typename: item?.__typename || "",
                              PostalCode: item?.postalCode || "",
                              State: item?.state || "",
                              City: item?.city || "",
                            }))}
                            target="_blank"
                            filename={"export-leads.csv"}
                          >
                            <Tooltip title="Download To CSV">
                              <CloudDownloadOutlinedIcon />
                            </Tooltip>
                          </CSVLink>
                        </GridContainer>
                        <GridContainer item xs={12} sm={3}>
                          <Tooltip
                            title="Download To XML"
                            onClick={() => this.handleConvertToXML()}
                          >
                            <CloudDownloadOutlinedIcon />
                          </Tooltip>
                        </GridContainer>
                      </GridContainer>
                    </div>
                  </div>
                  <Grid rows={data.leads_with_dealer_info} columns={columns}>
                    <SelectionState
                      selection={selection}
                      onSelectionChange={this.changeSelection}
                    />
                    <SearchState
                      value={searchValue}
                      onValueChange={this.changeSearchValue}
                    />
                    <SortingState
                      defaultSorting={[
                        { columnName: "lead_id", direction: "desc" },
                      ]}
                    />
                    <PagingState defaultCurrentPage={0} pageSize={10} />
                    <IntegratedSelection />
                    <IntegratedFiltering />
                    <IntegratedSorting />
                    <IntegratedPaging />
                    <RowDetailState defaultExpandedRowIds={[0]} />
                    <Table />
                    <TableHeaderRow showSortingControls />
                    <TableRowDetail contentComponent={RowDetail} />
                    <TableSelection showSelectAll />
                    <PagingPanel />
                    <Toolbar />
                    <SearchPanel />
                  </Grid>
                </Paper>
              </div>
            );
          }}
        </Query>
      </DashboardLayout>
    );
  }
}
Leads.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Leads);
