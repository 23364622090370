import React from "react";
import {
  Page,
  Image,
  Text,
  View,
  Document,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";

Font.register({
  family: "TimesNewRoman",
  src: "https://fonts.gstatic.com/s/tinos/v9/R0GUby9C7Xd7F2g6Wjdydw.ttf",
});

const fontFamily = "TimesNewRoman"; //'Times New Roman';
const pageWidth = "540px";

const styles = StyleSheet.create({
  page: {
    textAlign: "left",
    display: "flex",
    height: "100%",
    margin: 30,
    fontFamily,
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "left",
    width: "50%",
    fontFamily,
  },
  rowContainerFull: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "left",
    width: pageWidth,
    fontFamily,
  },
  hr: {
    borderBottom: "2pt solid #000",
    width: pageWidth,
    padding: 8,
  },
  space1: {
    width: pageWidth,
    padding: 2,
  },
  space2: {
    width: pageWidth,
    padding: 8,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    fontFamily,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "140%",
    flex: 1,
    fontFamily,
  },
  field_header: {
    padding: 2,
    fontSize: 12,
    fontWeight: 700,
    fontFamily,
  },
  field_text: {
    padding: 2,
    fontSize: 10,
    fontFamily,
    maxWidth: pageWidth,
  },
  sectionHeaderImage: {
    width: pageWidth,
    marginBottom: 24,
    fontFamily,
  },
  sectionHeader: {
    color: "#000000",
    fontSize: 10,
    marginTop: 6,
    marginBottom: 12,
    fontFamily,
  },
  pageNumbers: {
    position: "absolute",
    fontSize: 14,
    bottom: 5,
    left: 0,
    right: 0,
    textAlign: "center",
    fontFamily,
  },
});

const longest = (arr) =>
  arr?.sort(function (a, b) {
    return b?.length - a?.length;
  })[0];

export function CatalogRequestPdfDocument(props) {
  const newCatalogRequest = props?.data;
  if (!newCatalogRequest) return null;
  const dealerforLetter = newCatalogRequest.dealerforLetter.profiles_by_pk;
  const letterHead = newCatalogRequest.letterHead.leads_by_pk;
  const nearestDealers =
    newCatalogRequest?.nearestDealers?.nearest_dealer_to_lead;
  const options = newCatalogRequest?.options
    ? JSON.parse(unescape(newCatalogRequest?.options))
    : "";

  const boatColor = options?.boatColor;
  const boatOptions = options?.boatOptions;
  const napOptions = options?.napOption;
  const specialOptions = options?.boatSpecialOptions;
  const totalPrice = options?.totalprice;
  const boatSpecification = options?.boatSpecification;

  const specUI = (
    <>
      <Text style={{ fontWeight: "bold", paddingVertical: 4, fontSize: 12 }}>
        Specifications:
      </Text>
      {boatSpecification && boatSpecification.length > 0
        ? boatSpecification?.map((spec, index) => (
            <View key={index} style={{ marginVertical: 4 }}>
              <Text
                style={{ fontWeight: "bold", marginBottom: 2, fontSize: 10 }}
              >
                {spec.heading || ""}
              </Text>
              {spec.lists.map((listItem, listIndex) => (
                <Text key={listIndex} style={{ marginBottom: 2, fontSize: 10 }}>
                  {listItem || ""}
                </Text>
              ))}
            </View>
          ))
        : null}
    </>
  );

  const boatLI =
    boatOptions?.length > 0 ? (
      <>
        <Text style={{ fontWeight: "bold", paddingVertical: 4, fontSize: 12 }}>
          Option :
        </Text>
        <View>
          {boatOptions?.map((item, index) => (
            <View style={styles.rowContainer}>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Text break style={styles.field_text}>
                  {item?.title || item}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.column}>
                  <Text break style={styles.field_text}>
                  {item?.price || "$0"}
                  </Text>
                </View>
              </View>
            </View>
          ))}
        </View>
      </>
    ) : null;

  const napLI =
    napOptions?.length > 0 ? (
      <>
        <Text style={{ fontWeight: "bolder", paddingVertical: 4, fontSize: 12 }}>
          Boat Model :
        </Text>
        <View>
          {napOptions?.map((item, index) => (
            <View style={styles.rowContainer}>
            <View style={styles.row}>
              <View style={styles.column}>
                <Text break style={styles.field_text}>
                  {item?.title || item}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.column}>
                <Text break style={styles.field_text}>
                  {item?.price || "$0"}
                </Text>
              </View>
            </View>
          </View>
            // <View
            //   key={index}
            //   style={{
            //     flexDirection: "row",
            //     justifyContent: "space-between",
            //     alignItems: "center",
            //     paddingVertical: 2,
            //     width: "100%",
            //     maxWidth: pageWidth - 60,
            //   }}
            // >
            //   <Text style={{ ...styles.field_text, flex: 1 }}>
            //     {item?.title || item}
            //   </Text>
            //   <Text
            //     style={{
            //       ...styles.field_text,
            //       flexShrink: 0,
            //       marginLeft: 8,
            //       textAlign: "center",
            //       width: "100%",
            //     }}
            //   >
            //     {item?.price || "$0"}
            //   </Text>
            // </View>
          ))}
        </View>
      </>
    ) : null;

  const colorUI =
    typeof boatColor === "string" ? (
      <>
        <Text style={{ fontWeight: "bold", paddingVertical: 4, fontSize: 12 }}>
          Boat Color:
        </Text>

        <View style={styles.rowContainer}>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text break style={styles.field_text}>
              {boatColor}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text break style={styles.field_text}>
              $0
              </Text>
            </View>
          </View>
        </View>
      </>
    ) : boatColor?.length ? (
      <>
        <Text style={{ fontWeight: "bold", paddingVertical: 4, fontSize: 12 }}>
          Boat Color:
        </Text>
        {boatColor?.map((item, index) => (
          <View style={styles.rowContainer}>
            <View style={styles.row}>
              <View style={styles.column}>
                <Text break style={styles.field_text}>
                {item?.title || item}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.column}>
                <Text break style={styles.field_text}>
                {item?.price || "$0"}
                </Text>
              </View>
            </View>
          </View>
        ))}
      </>
    ) : null;

  const specialUI = specialOptions?.length ? (
    <>
      <Text style={{ fontWeight: "bold", paddingVertical: 4, fontSize: 12 }}>
        Special Option:
      </Text>
      {specialOptions?.map((item, index) => (
        <View
          key={index}
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: 2,
            width: "100%",
            maxWidth: pageWidth - 60,
          }}
        >
          <Text style={{ ...styles.field_text, flex: 1, textAlign: "left" }}>
            {item?.title || item}
          </Text>
          <Text
            style={{
              ...styles.field_text,
              flexShrink: 0,
              textAlign: "center",
              width: "100%",
            }}
          >
            {item?.option || ""}
          </Text>
        </View>
      ))}
    </>
  ) : null;

  const totalPriceUI = (
    <View style={styles.rowContainer}>
      <View style={styles.row}>
        <View style={styles.column}>
          <Text break style={styles.field_text}>
            Total Price:
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column}>
          <Text break style={styles.field_text}>
            {totalPrice || "$0"}
          </Text>
        </View>
      </View>
    </View>
    // <View
    //   style={{
    //     flexDirection: "row",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     paddingVertical: 2,
    //     width: "100%",
    //     maxWidth: pageWidth - 60,
    //   }}
    // >
    //   <Text style={{ ...styles.field_text, flex: 1, textAlign: "left" }}>
    //     Total Price:
    //   </Text>
    //   <Text
    //     style={{
    //       ...styles.field_text,
    //       flexShrink: 0,
    //       textAlign: "center",
    //       width: "100%",
    //     }}
    //   >
    //     {totalPrice || "$0"}
    //   </Text>
    // </View>
  );

  const parseAddress = require("parse-address-string");

  parseAddress(letterHead.address, (err, addressObj) => {
    // console.log(addressObj);
  });

  const reverse = require("reverse-geocode");

  // gets address from lat and long
  const coords = newCatalogRequest?.location?.coordinates;
  const lookup = reverse?.lookup(coords?.[1], coords?.[0], "us");
  // console.log(lookup);

  // remove the lookup data from the address
  const step1 = longest(letterHead?.address?.split(lookup?.city));
  // console.log(step1);
  const step2 = longest(step1?.split(lookup?.state));
  // console.log(step2);
  const step3 = longest(step2?.split(lookup?.state_abbr));
  // console.log(step3);
  const step4 = longest(step3?.split(lookup?.zipcode));
  // console.log(step4);

  // Remove double spaces
  const step5 = step4?.replace(/\s{2,}/g, " ");
  // console.log(step5);

  return (
    <Document>
      <Page style={styles.page}>
        {/* <Image
          style={styles.sectionHeaderImage}
          src={{ uri: newCatalogRequest.uri, method: "GET" }}
        /> */}
        <View style={styles.rowContainerFull}>
          <View style={styles.row}>
            <View style={styles.column}>
              {/* <Text style={styles.space2}></Text>
              <Text style={styles.space2}></Text>
              <Text style={styles.space2}></Text> */}
              {/* <Text style={styles.space2}></Text> */}
              <Text break style={styles.field_text}>
                {letterHead?.name}
              </Text>
              <Text wrap={true} style={styles.field_text}>
                {step5}
              </Text>
              <Text wrap={true} style={styles.field_text}>
                {lookup?.city}, {lookup?.state_abbr} {lookup?.zipcode}
              </Text>
              <Text style={styles.space2}></Text>
              <Text style={styles.space2}></Text>
              <Text style={styles.space2}></Text>

              <Text break style={styles.sectionHeader}>
                Dear Consumer,
              </Text>

              <Text break style={styles.field_text}>
                Thank you for your interest in one of the most durable, stable
                and affordable boats on the water today...If you’re looking for
                a safe and reliable boat that won’t sink your wallet, then you
                need look no further than the Carolina Skiff and Sea Chaser line
                of boats.
              </Text>
              <Text style={styles.space1}></Text>
              <Text break style={styles.field_text}>
                We believe that the true freedom of boating begins before you
                even touch the water. With a combination of composite materials,
                gel-coat, versatility, and proven reliability, you can have a
                boat that will create many years of excitement and long lasting
                memories of fun with family and friends.
              </Text>
              <Text style={styles.space1}></Text>
              <Text break style={styles.field_text}>
                Enclosed please find the Carolina Skiff and or Sea Chaser
                brochure you’ve requested. We know you’ll appreciated the real
                thing even more, so we encourage you to see the full line
                Carolina Skiff and or Sea Chaser boats up close and personnel at
                your nearest authorized Dealer.
              </Text>

              <Text style={styles.space2}></Text>
              <Text break style={styles.sectionHeader}>
                Thank you for your interest,
              </Text>

              <Text break style={styles.field_text}>
                Carolina Skiff LLC.
              </Text>
              <Text break style={styles.field_text}>
                Family – Fishing - Fun
              </Text>
            </View>
          </View>
        </View>
        <Text style={styles.hr}></Text>
        <Text style={styles.sectionHeader}>Authorized Nearest Dealer</Text>
        <View style={styles.rowContainer}>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text break style={styles.field_text}>
                Dealership Name: {dealerforLetter?.name}
              </Text>
              <Text break style={styles.field_text}>
                Address line: {dealerforLetter?.address}
              </Text>
              <Text break style={styles.field_text}>
                Address line2: {dealerforLetter?.address2}
              </Text>
              <Text break style={styles.field_text}>
                Phone number: {dealerforLetter?.phone}
              </Text>
              <Text break style={styles.field_text}>
                Web: {dealerforLetter?.website}
              </Text>
            </View>
          </View>
        </View>
        <Text style={styles.hr}></Text>
        <Text style={styles.sectionHeader}>
          Other Authorized Dealers in your area
        </Text>
        <View style={styles.rowContainer}>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text break style={styles.field_text}>
                Dealership Name: {nearestDealers?.[0]?.name}
              </Text>
              <Text break style={styles.field_text}>
                Address line: {nearestDealers?.[0]?.address}
              </Text>
              <Text break style={styles.field_text}>
                Address line2: {nearestDealers?.[0]?.address2}
              </Text>
              <Text break style={styles.field_text}>
                Phone number: {nearestDealers?.[0]?.phone}
              </Text>
              <Text break style={styles.field_text}>
                Web: {nearestDealers?.[0]?.website}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text break style={styles.field_text}>
                Dealership Name: {nearestDealers?.[1]?.name}
              </Text>
              <Text break style={styles.field_text}>
                Address line: {nearestDealers?.[1]?.address}
              </Text>
              <Text break style={styles.field_text}>
                Address line2: {nearestDealers?.[1]?.address2}
              </Text>
              <Text break style={styles.field_text}>
                Phone number: {nearestDealers?.[1]?.phone}
              </Text>
              <Text break style={styles.field_text}>
                Web: {nearestDealers?.[1]?.website}
              </Text>
            </View>
          </View>
        </View>
        <Text style={styles.hr}></Text>
        <View style={styles.rowContainerHalf}>
          <View style={styles.column}>{napLI}</View>
        </View>
        <View style={styles.rowContainerHalf}>
          <View style={styles.column}>{colorUI}</View>
        </View>
        <View style={styles.rowContainerHalf}>
          <View style={styles.column}>{boatLI}</View>
        </View>
        <View style={styles.rowContainerHalf}>
          <View style={styles.column}>{totalPriceUI}</View>
        </View>
        <View style={styles.rowContainerHalf}>
          <View style={styles.column}>{specialUI}</View>
        </View>
        <View style={styles.rowContainerHalf}>
          <View style={styles.column}>{specialUI}</View>
        </View>
        <View style={styles.rowContainerHalf}>
          <View style={styles.column}>{specUI}</View>
        </View>
        <Text
          style={styles.pageNumbers}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
